<template>
  <div
    v-if="show"
    ref="create-loan-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CREATE LOAN ACCOUNT PAGE-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a class="modal__close modal__close--black" @click="$emit('close')">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">
            Create New Loan Account
          </h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="form__item">
              <label class="form__label text--regular text--black"
                >Account Recipient</label
              >
              <p
                class="text--600 text--capital text--large text--primary"
                v-if="whatPage === 'customer'"
              >
                {{ accountRecipient }}
                <!-- <span
                  class="error--text text--tiny"
                  v-if="$v.form.$dirty && !$v.form.selectedCustomer.required"
                >
                  Customer is required
                </span> -->
              </p>
              <template v-else>
                <multiselect
                  v-model="form.selectedCustomer"
                  id="ajax"
                  label="customerName"
                  track-by="customerKey"
                  placeholder="Type to search for client by name"
                  open-direction="bottom"
                  :options="customers"
                  :multiple="false"
                  :searchable="true"
                  :loading="loadingCustomers"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="searchCustomersSingle"
                >
                  <template slot="tag" slot-scope="{ option }"
                    ><span class="custom__tag"
                      ><span>{{
                        option.customerFirstName + " " + option.customerLastName
                      }}</span></span
                    ></template
                  >
                  <span slot="noResult">Oops! No result found.</span>
                </multiselect>
                <!-- <span
                  class="error--text text--tiny"
                  v-if="$v.form.$dirty && !$v.form.selectedCustomer.required"
                >
                  Customer is required
                </span> -->
              </template>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product</label>
                  <select class="select select--lg" v-model="productTypeKey">
                    <option value="null" disabled selected v-if="!loading">
                      Select Product
                    </option>
                    <option value="null" disabled selected v-else>
                      Loading...
                    </option>
                    <template v-if="!loading">
                      <option
                        v-for="item in sortedProducts"
                        :key="item.loanProductKey"
                        v-bind:value="item.loanProductKey"
                      >
                        {{ item.productName }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Display Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Display Name"
                    v-model="loanName"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Product Type</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ productType }}
                  </p>
                  <p class="text--black text--500 text--small">
                    {{ productDescription }}
                  </p>
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__action">
                <button
                  id="save-changes"
                  type="button"
                  class="button form__button form__button--lg float-right"
                  @click="goToLoanCreation()"
                >
                  {{ process ? "Saving..." : "Create Account" }}
                </button>
                <a
                  data-dismiss="#newDepositModal"
                  role="button"
                  id="closeBtn"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function initialState() {
  return {
    form: {
      assignedBranchKey: null,
      selectedCustomer: null,
      accountState: "PENDING_APPROVAL",
      accountHolderType: "CLIENT",
    },
    bank: {},
    isValidating: false,
    assignedBranchKey: null,
    arrearsTolerancePeriod: null,
    arrearsToleranceAmount: null,
    repaymentInstallments: null,
    repaymentPeriodCount: null,
    repaymentPeriodUnit: null,
    confirm: false,
    showInactiveFees: true,
    process: false,
    productTypeKey: null,
    minLoanAmount: null,
    maxLoanAmount: null,
    whatPage: "customer",
    branchList: [],
    customers: [],
    scheduleReady: false,
    loadingSchedule: false,
    loadingCustomers: false,
    loadingGuarantorAccounts: false,
    generatingSchedule: false,
    guarantorAccounts: null,
    accountHolderKey: null,
    alert: {
      show: false,
      status: "",
      title: "Success",
      description: "Loan Product has been created",
    },
    disbursementDetails: {
      anticipatedDisbursementDate: null,
      firstRepaymentDate: null,
      bankKey: null,
      bankAccountNumber: null,
      bankAccountName: null,
    },
    accountArrearsSettings: {
      dateCalculationMethod: "",
      monthlyToleranceDay: 0,
      nonWorkingDaysMethod: "",
      toleranceCalculationMethod: "",
      toleranceFloorAmount: 0,
      tolerancePercentageOfOutstandingPrincipal: null,
      tolerancePeriod: 0,
    },
    guarantees: [],
    repayments: [],
    collateralAssets: [],
    guaranteeModel: {
      source: null,
      account: null,
      amount: null,
      accountList: [],
      sourceList: [],
      loadingSource: false,
      loadingAccounts: false,
      errorAccount: false,
      errorAmount: false,
      errorSource: false,
    },
    collateralModel: {
      description: null,
      amount: null,
      errorDescription: false,
      errorAmount: false,
    },
    scheduleGenerationError: null,
    defaultFirstRepaymentDueDateOffset: 0,
    gracePeriod: 0,
    gracePeriodType: "",
    interestCalculationMethod: "",
    interestRate: null,
    interestChargeFrequency: "NA",
    loanAmount: 0,
    loanName: "",
    productType: "",
    productDescription: "",
    notes: "",
  };
}
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import LoanApiService from "@/core/services/general.service";
import { GET_ALL_LOAN_PRODUCTS } from "@/core/services/store/types";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateLoanAccountPage",
  components: {
    Multiselect,
  },
  props: {
    show: Boolean,
    close: Function,
    customer: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data: function () {
    return initialState();
  },
  validations: {
    loanAmount: { required },
    interestRate: { required },
    repaymentPeriodUnit: { required },
    repaymentPeriodCount: { required },
    repaymentInstallments: { required },
    arrearsTolerancePeriod: { required },
    arrearsToleranceAmount: { required },
    form: {
      assignedBranchKey: { required },
      //selectedCustomer: { required },
    },
  },
  methods: {
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    controlValue(prop, val, min, max, e) {
      let transVal = e.target.value;
      if (e) {
        if (/[^0-9.]/g.test(e.target.value)) {
          transVal = e.target.value.replace(/([^0-9.])/g, "");
          if (transVal < min && min) {
            this[prop] = min;
            e.target.value = min;
          } else if (transVal > max && max) {
            this[prop] = max;
            e.target.value = max;
          } else if (parseInt(transVal) < 0) {
            this[prop] = "";
            e.target.value = "";
          } else {
            this[prop] = transVal;
            e.target.value = transVal;
          }
        } else {
          if (transVal < min && min) {
            this[prop] = min;
            e.target.value = min;
          } else if (transVal > max && max) {
            this[prop] = max;
            e.target.value = max;
          } else if (parseInt(transVal) < 0) {
            this[prop] = "";
            e.target.value = "";
          } else {
            this[prop] = transVal;
            e.target.value = transVal;
          }
        }
      }
    },
    controlEdit(d, min, max) {
      if (d == min && min && d == max && max) {
        return true;
      }
      return false;
    },
    getLoanProducts() {
      this.$store.dispatch(GET_ALL_LOAN_PRODUCTS);
    },
    searchCustomersSingle(search) {
      const self = this;
      if (search.length) {
        self.loadingCustomers = true;
        LoanApiService.get(
          "Customer/search/" +
            search
        )
          .then((res) => res.data.data.items)
          .then((res) => {
            self.loadingCustomers = false;
            self.customers = res;
          })
          .catch(() => {
            self.loadingCustomers = false;
          });
      }
    },
    searchCustomers(search, index) {
      const self = this;
      if (search.length) {
        self.guarantees[index].loadingSource = true;
        LoanApiService.get(
          "Customer/search/" +
            search
        )
          .then((res) => res.data.data.items)
          .then((res) => {
            self.guarantees[index].loadingSource = false;
            self.guarantees[index].sourceList = res;
          })
          .catch(() => {
            self.guarantees[index].loadingSource = false;
          });
      }
    },
    resetWindow: function () {
      Object.assign(this.$data, initialState());
    },
    setSelectedProduct(id) {
      const p = this.products.find((item) => item.loanProductKey === id);
      this.productTypeKey = id;
      this.loanName = p.productName;
      this.productType = p.loanProductType;
      this.productDescription = p.productDescription;
      this.loanAmount =
        p.minLoanAmount > p.defaultLoanAmount
          ? p.minLoanAmount
          : p.defaultLoanAmount;
      this.maxLoanAmount = p.maxLoanAmount ? p.maxLoanAmount : null;
      this.minLoanAmount = p.minLoanAmount ? p.minLoanAmount : null;
      this.gracePeriod = p.defaultGracePeriod ? p.defaultGracePeriod : 0;
      this.gracePeriodType = p.gracePeriodType ? p.gracePeriodType : 0;
      this.repaymentPeriodCount = p.repaymentPeriodUnit
        ? p.repaymentPeriodUnit
        : 0;
      this.repaymentPeriodUnit = p.repaymentReschedulingMethod
        ? p.repaymentReschedulingMethod == "NA"
          ? 0
          : 0
        : 0;
      this.repaymentInstallments = p.defaultFirstRepaymentDueDateOffset
        ? p.defaultFirstRepaymentDueDateOffset
        : null;
      this.repaymentScheduleMethod = p.repaymentScheduleMethod
        ? p.repaymentScheduleMethod
        : null;
      this.interestCalculationMethod = p.interestCalculationMethod
        ? p.interestCalculationMethod
        : null;
      this.interestRate = p.adminInterestProductSettings
        ? p.adminInterestProductSettings.defaultInterestRate
          ? p.adminInterestProductSettings.defaultInterestRate
          : null
        : null;
      this.arrearsTolerancePeriod = p.adminArrearsResponseModel
        ? p.adminArrearsResponseModel.defaultTolerancePeriod
          ? p.adminArrearsResponseModel.defaultTolerancePeriod
          : null
        : null;
      this.arrearsToleranceAmount = p.adminArrearsResponseModel
        ? p.adminArrearsResponseModel
            .defaultTolerancePercentageOfOutstandingPrincipal
          ? p.adminArrearsResponseModel
              .defaultTolerancePercentageOfOutstandingPrincipal
          : null
        : null;
      this.accountArrearsSettings.tolerancePercentageOfOutstandingPrincipal =
        p.adminArrearsResponseModel
          ? p.adminArrearsResponseModel
              .defaultTolerancePercentageOfOutstandingPrincipal
            ? p.adminArrearsResponseModel
                .defaultTolerancePercentageOfOutstandingPrincipal
            : null
          : null;
      this.accountArrearsSettings.monthlyToleranceDay =
        p.adminArrearsResponseModel
          ? p.adminArrearsResponseModel.monthlyToleranceDsay
            ? p.adminArrearsResponseModel.monthlyToleranceDsay
            : 0
          : 0;
      this.defaultFirstRepaymentDueDateOffset = p.adminArrearsResponseModel
        ? p.adminArrearsResponseModel.defaultInterestRate
          ? p.adminArrearsResponseModel
              .defaultTolerancePercentageOfOutstandingPrincipal
          : null
        : null;
      // this.$v.form.$reset()
    },
    goToLoanCreation() {
      this.$router.push({
        path: "/loan/account/create",
        query: {
          customer_id: this.customer.customerKey,
          product_id: this.productTypeKey,
        },
      });
    },
  },
  watch: {
    productTypeKey: function (id) {
      this.setSelectedProduct(id);
    },
    show(change) {
      if (change) {
        if (window.location.hash.indexOf("customer") != -1) {
          this.whatPage = "customer";
        } else {
          this.whatPage = "group";
        }
      }
    },
    products(change) {
      if (change) {
        // this.loanProduct = change[0];
       // this.setSelectedProduct(change[0].loanProductKey);
        this.$v.$reset();
      }
    },
    "disbursementDetails.firstRepaymentDate": function () {
      this.scheduleGenerationError = null;
    },
  },
  computed: {
    ...mapState({
      enums: (state) => state.loan.enums,
      loading: (state) => state.loan.loading_all,
      products: (state) =>
        state.loan.products_all
          ? state.loan.products_all.data
            ? state.loan.products_all.data
            : []
          : [],
      saving: (state) => state.loan.saving,
      loanProductDescription() {
        return this.loanProduct ? this.loanProduct.productDescription : "";
      },
      loanProductType() {
        return this.loanProduct
          ? this.loanProduct.loanProductType.replace(/_/g, " ")
          : "";
      },
      accountRecipient() {
        if (this.customer) {
          if (this.customer.customerFullname) {
            return this.customer.customerFullname;
          }
        }
        return "";
      },
    }),
    sortedProducts() {
      const productsArray = this.products;
      return productsArray.sort((a, b) => a.productName.localeCompare(b.productName));
    },
  },
  mounted() {
    this.getLoanProducts();
  },
};
</script>
