<template>
  <div
    v-if="showEdit"
    class="modal modal__right"
    :class="{ show: showEdit === true }"
    :style="{ display: 'block' }"
    id="editModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#editModal"
              class="modal__close modal__close--black"
              @click="$emit('close')"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Edit Customer</h5>
        </div>
        <div class="modal__body">
          <form>
            <ul id="accordion" class="mb5">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccgeneral"
                  aria-expanded="false"
                  aria-controls="ccgeneral"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    General
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="ccgeneral" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">First Name</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter First Name"
                            v-model="customerForm.customerFirstName"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerFirstName,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Middle Name</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Middle Name"
                            v-model="customerForm.customerMiddleName"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Last Name</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Last Name"
                            v-model="customerForm.customerLastName"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerLastName,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Customer Type</label>

                          <select
                            class="select--lg w-100"
                            v-model="customerForm.customerTypesKey"
                            @change="
                              getCustomFormFields(
                                'Customers',
                                $event.target.value
                              )
                            "
                            required
                          >
                            <option disabled value="null">
                              Select Customer type
                            </option>
                            <option
                              v-for="item in sortCustomerType"
                              v-bind:key="item.customerTypeKey"
                              v-bind:value="item.customerTypeKey"
                            >
                              {{ item.clientTypeName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">ID</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter ID"
                            v-model="customerForm.uniqueCustomerId"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.uniqueCustomerId,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label col-4">BVN</label>
                          <label
                            v-if="requiredBVN"
                            style="padding:0px !important"
                            class="form__label col-8 text-danger"
                          >BVN is required</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Bvn"
                            :disabled="!userCanEditClientBVN"
                            @input="limitBVNEntry"
                            v-model="customerForm.bvn"
                            v-bind:class="{
                              'input-required': customerErrorForm.bvn,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccdetails"
                  aria-expanded="false"
                  aria-controls="ccdetails"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Details
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="ccdetails" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Date of Birth</label>
                          {{ customerForm.customerBirthDate }}
                          <input
                            type="date"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Date of Birth"
                            v-model="customerForm.customerBirthDate"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerBirthDate,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Gender</label>
                          <select
                            class="select select--lg"
                            v-model="customerForm.customerGender"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerGender,
                            }"
                          >
                            <option value="null" disabled>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Mobile Phone</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Mobile Phone"
                            v-model="customerForm.customerMobilePhone1"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Home Phone</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder=""
                            v-model="customerForm.customerHomePhone"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <!-- <label class="form__label">Email address</label> -->
                          <label class="form__label col-4">Email</label>
                          <label
                            v-if="requiredEmail"
                            style="padding:0px !important"
                            class="form__label col-8 text-danger"
                          >Email is required</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter email address"
                            v-model="customerForm.customerEmailAddress"
                            @input="validateEmailCustomer(customerForm.customerEmailAddress)"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.customerEmailAddress,
                            }"
                          />
                          <p class="text--danger" v-if="customerErrorForm.customerEmailAddress">Please provide a valid email</p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Mobile Phone2</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Mobile Phone2"
                            v-model="customerForm.customerMobilePhone2"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Home Address</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Home Address"
                            v-model="customerForm.customerHomeAddress"
                          />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#cchomeaddresssection"
                  aria-expanded="false"
                  aria-controls="cchomeaddresssection"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Home Address Section
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="cchomeaddresssection" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">City</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter City"
                            v-model="customerForm.city"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">State/Province/Region</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter State/Province/Region"
                            v-model="customerForm.state"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Zip Postal Code</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Zip Postal Code"
                            v-model="customerForm.zipPostalCode"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Country</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Country"
                            v-model="customerForm.country"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Street Address - Line 1</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Street Address Line 1"
                            v-model="customerForm.streetAddressLine1"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form__item">
                          <label class="form__label">Street Address - Line 2</label>
                          <input
                            type="text"
                            class="
                              input input--right
                              form__input form__input--lg
                            "
                            placeholder="Enter Street Address Line 2"
                            v-model="customerForm.streetAddressLine2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#customerIdDocs"
                  aria-expanded="false"
                  aria-controls="customerIdDocs"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Identification Documents
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="customerIdDocs"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div
                    class="row"
                    v-for="(
                      idTemp, index
                    ) in customerForm.identificationDocuments"
                    v-bind:key="
                      idTemp.identificationDocumentTemplateKey + '' + index
                    "
                  >
                    <div class="col-md-6">
                      Document Type: {{ idTemp.documentType }}
                    </div>
                    <div class="col-md-6">
                      Issuing Authority: {{ idTemp.issuingAuthority }}
                    </div>
                    <div class="col-md-6">
                      <div class="form__item">
                        <label>Document ID</label>
                        <input
                          type="text"
                          v-model="idTemp.documentId"
                          class="input form__input form__input--lg"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form__item">
                        <label>Valid Until</label>
                        <input
                          type="date"
                          v-model="idTemp.validUntil"
                          :min="currentDate"
                          class="input form__input form__input--lg"
                        />
                      </div>
                    </div>

                    <div v-if="idTemp.allowAttachments" class="col-12">
                        <div class="col-md-12">
                          <div class="form__item form__item_dottedbg">
                            <div class="p-5 text--center position-relative">
                              <input
                                :id="`attachment-${index}`"
                                type="file"
                                class="input form__input--lg"
                                accept="*"
                                ref="myFiles"
                                @change="onFileChange(`attachment-${index}`, index)"
                                hidden
                              />

                              <button
                                class="
                                  my-2
                                  button button--black
                                  text--500
                                  text--white
                                  text--small
                                  text--capital
                                "
                                @click.prevent="
                                  callFileUpload(`attachment-${index}`)
                                "
                              >
                                Choose File
                              </button>
                            </div>
                            <ul class="list-group">
                              <li class="list-group-item">
                                {{ idTemp.filename }} ({{ idTemp.fileSize | kb }} kb)
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="row align-items-center col-md-12" v-if="idTemp.showDoc">
                          <div class="col-10">
                            <div class="form__item">
                              <label 
                                @click.prevent="downloadAttachment(index, idTemp.attachments[0].documentDescription)"
                                class="download-attach"
                              >{{ idTemp.attachments[0].documentOriginalFileName }}</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form__item">
                              <button
                                class="
                                  button
                                  page__body__header__button
                                  modal__confirm__button--delete
                                  button--sm
                                  text--black
                                  w-100
                                  text--capital
                                "
                                @click.prevent="deleteAttachment(index, idTemp.attachments[0].documentDescription)"
                              >
                                <span>X</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form__item">
                            <button
                              :disabled="loading"
                              class="
                                my-2
                                button button--black
                                text--500 text--white text--small text--capital
                              "
                              @click.prevent="uploadFile(index)"
                            >
                              {{ fileLoading ? "Uploading..." : "Upload File" }}
                            </button>
                          </div>
                        </div>
                    </div>

                    <div class="form__item">
                      <button
                        class="
                          button
                          page__body__header__button
                          ml-auto
                          button--grey
                          text--capital
                        "
                        type="button"
                        @click="idTemplateRemove('Customer', index)"
                      >
                        Remove Identification Document
                        <i class="ki ki-plus icon-sm ml-3"> </i>
                      </button>
                    </div>
                  </div>

                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label">Select Template</label>
                      <select
                        class="select select--lg"
                        v-model="customerForm.selectedIdTemplate"
                      >
                        <option :value="null" disabled selected>
                          Select ID Doc
                        </option>
                        <option
                          v-for="item in idTemplates"
                          v-bind:key="item.identificationDocumentTemplateKey"
                          v-bind:value="item.identificationDocumentTemplateKey"
                        >
                          {{ item.documentType }}
                        </option>
                      </select>
                    </div>

                    <div class="form__item">
                      <button
                        class="
                          button
                          page__body__header__button
                          ml-auto
                          button--grey
                          text--capital
                        "
                        type="button"
                        @click="addIdTemplateToForm('Customer')"
                      >
                        Add Identification Document
                        <i class="ki ki-plus icon-sm ml-3"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccassociated"
                  aria-expanded="false"
                  aria-controls="ccassociated"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Associated
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="ccassociated"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Branch</label>
                          <select
                            class="select--lg w-100"
                            v-model="customerForm.assignedBranchKey"
                            @change="branchSelectChange($event)"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.assignedBranchKey,
                            }"
                          >
                            <option disabled value="null">Select branch</option>
                            <option
                              v-for="item in allBranches"
                              v-bind:key="item.branchKey"
                              v-bind:value="item.branchKey"
                            >
                              {{ item.branchName }} ({{ item.branchId }})
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Center</label>
                          <select
                            class="select--lg w-100"
                            v-model="customerForm.assignedCentreKey"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.assignedCentreKey,
                            }"
                          >
                            <option disabled value="null">Select Center</option>
                            <option
                              v-for="item in branchCenter"
                              v-bind:key="item.centerKey"
                              v-bind:value="item.centerKey"
                            >
                              {{ item.centerName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Account Officer</label>
                          <select
                            class="select--lg w-100"
                            v-model="customerForm.assignedUserKey"
                            v-bind:class="{
                              'input-required':
                                customerErrorForm.assignedUserKey,
                            }"
                          >
                            <option disabled :value="null">Select Account Officer</option>
                            <option
                              v-for="item in accountOfficers"
                              v-bind:key="item.userKey"
                              v-bind:value="item.userKey"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal__accordion"
                v-for="(set, index) in customerForm.customFieldSetFieldsAndValue"
                :key="set.fieldSetKey"
              >
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  :data-target="`#ccSet-${set.fieldSetKey}${index}`"
                  aria-expanded="false"
                  aria-controls="ccprofileNote"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    {{ set.fieldSetName }}
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  :id="`ccSet-${set.fieldSetKey}${index}`"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6" v-for="(field, i) in set.fields" :key="field.fieldKey">
                        <div v-if="field.dataType == 'Number'" class="form__item">
                          <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                          <input
                            type="number"
                            class="input form__input form__input--lg"
                            placeholder="Enter Field value"
                            :required="field.isRequired"
                            @input="editAccountValidateNumber(field.value, index, i)"
                            v-model="field.value"
                          />
                        </div>
                        <div v-if="field.dataType == 'Checkbox'" class="form__item">
                          <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                            <input
                              type="checkbox"
                              id="checkbox"
                              class="input form__input form__input--lg"
                              placeholder="Enter Field value"
                              :required="field.isRequired"
                              v-model="field.value"
                            />
                        </div>
                        <div v-if="field.dataType == 'Free text'" class="form__item">
                          <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter Field value"
                            :required="field.isRequired"
                            v-model="field.value"
                          />
                        </div>
                        <div v-if="field.dataType == 'Date'" class="form__item">
                          <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                          <input
                            type="date"
                            class="input form__input form__input--lg"
                            placeholder="Enter Field value"
                            :required="field.isRequired"
                            v-model="field.value"
                          />
                        </div>
                        <div v-if="field.dataType == 'Selection'" class="form__item">
                          <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                          <select
                            class="select select--lg"
                            :required="field.isRequired"
                            v-model="field.value"
                          >
                            <option value="" disabled selected>Select Field Value</option>
                            <option
                              v-for="filedValue in field.selectionFieldValues"
                              :key="filedValue.customFieldSelectionKey"
                              :value="filedValue.value"
                            >
                              {{ filedValue.value }}
                            </option>
                          </select>
                        </div>
                        <div
                          v-show="field.value"
                          v-if="['customer link', 'group link', 'user link'].includes(field.dataType.toLowerCase())"
                          class="form__item"
                        >
                          <label style="word-wrap: anywhere;" class="form__label">{{ field.fieldName }}</label>
                          <multiselect
                            v-model="field.value"
                            id="ajax"
                            label="name"
                            track-by="name"
                            :placeholder="`Type to search ${field.dataType.split(' ')[0]}`"
                            open-direction="bottom"
                            :options="field.searchValue ? field.searchValue : searchValues"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="false"
                            :clear-on-select="false"
                            :options-limit="300"
                            :limit="5"
                            :max-height="800"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="editAccountQuerySearch($event, index, i)"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag">
                                <span>{{ option.name }}</span>
                                <span class="custom__remove" @click="remove(option)"
                                  >❌</span
                                >
                              </span>
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion" v-if="requiredEmployer">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccpEmployer"
                  aria-expanded="false"
                  aria-controls="ccpEmployer"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Employer
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="ccpEmployer"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <label
                        style="padding:0px !important"
                        class="form__label col-8 text-danger"
                      >Employer is required</label>
                      <multiselect
                        v-model="employer"
                        id="employer"
                        label="name"
                        track-by="employerKey"
                        placeholder="Select an employer"
                        selectLabel=""
                        open-direction="bottom"
                        :options="sortedEmployers"
                        :searchable="true"
                        :internal-search="true"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="7"
                        :max-height="800"
                        :loading="loading"
                        :preselect-first="false"
                        @input="updateEmployer"
                      >
                        <template slot="tag" slot-scope="{ option }"
                          ><span class="custom__tag"
                            ><span>{{ option.name }}</span></span
                          ></template
                        >
                        <span slot="noResult">Oops! No result found.</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#ccprofileNote"
                  aria-expanded="false"
                  aria-controls="ccprofileNote"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Profile Note
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="ccprofileNote"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Profile Note"
                        v-model="customerForm.customerNotes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <div class="form__item">
              <div class="form__action">
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  data-dismiss="#editModal"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="saveCustomerForm"
                  v-else
                >
                  Save Customer
                </button>
                <a
                  data-dismiss="#editModal"
                  role="button"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  :disabled="isSaving"
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import customerService from "@/core/services/general-setup-service/customer.service";
import idTemplatesService from "@/core/services/general-setup-service/id-templates.service";
import branchesService from "@/core/services/organization-service/branches.service";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
import centresService from "@/core/services/organization-service/centres.service";
import ApiService from "@/core/services/api.service";
import AccessApiService from "@/core/services/access.service";
import GeneralApiService from '@/core/services/general.service';
import Multiselect from "vue-multiselect";
import moment from "moment";
import { GET_MENU } from "@/core/services/store/types";
export default {
  name: "EditCustomer",
  components: {
    Multiselect,
  },
  props: {
    showEdit: Boolean,
    customerFo: Object,
    userCanEditClientBVN: Boolean,
  },
  data: function () {
    return {
      fileLoading: false,
      customerForm: {
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        customerHomePhone: null,
        uniqueCustomerId: null,
        customFieldSetFieldsAndValue: [],
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: "English",
        customFieldValues: [],
        identificationDocuments: [],
        selectedIdTemplate: null,
        employeeKey: null,
        // customerHomeAddress: null,
        streetAddressLine1: null,
        streetAddressLine2: null,
        city: null,
        state: null,
        zipPostalCode: null,
        country: null,
      },
      customerErrorForm: {
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
        employeeKey: false,
      },
      allBranches: [],
      allUsers: [],
      userLoading: false,
      selectedUser: null,
      branchCenter: [],
      accountOfficers: [],
      customFields: [],
      isLoading: false,
      isSaving: false,
      idTemplates: [],
      currentDate: moment().format("YYYY-MM-DD"),
      searchValues: [],
      requiredBVN: false,
      requiredEmail: false,
      requiredEmployer: false,
      limitValue: "",
      employer: "",
      allEmployers: [],
    };
  },
  methods: {
    getMenu() {
      this.$store.dispatch(GET_MENU);
    },
    async getIdTemplates() {
      try {
        this.isLoading = true;
        const res = await idTemplatesService.get({ pageSize: 100000, pageIndex: 1 });
        this.idTemplates = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        if (error) return this.$emit("alertError", error);
      }
    },
    addIdTemplateToForm(form) {
      if (form == "Customer") {
        let templateKey = this.customerForm.selectedIdTemplate;
        let idtemp = this.idTemplates.find(
          (x) => x.identificationDocumentTemplateKey == templateKey
        );
        if (idtemp != null) {
          let toPush = {};
          Object.assign(toPush, idtemp);
          toPush.documentId = "";
          toPush.validUntil = "";
          toPush.filename = '';
          toPush.fileSize = 0;
          toPush.showDoc = false;

          this.customerForm.identificationDocuments.push(toPush);
        }
      }
    },
    idTemplateRemove(form, index) {
      if (form == "Customer") {
        this.customerForm.identificationDocuments.splice(index, 1);
      }
    },
    async downloadAttachment(index, key) {
      const name = this.customerForm.identificationDocuments[index].attachments[0].documentOriginalFileName;
      await ApiService.getFormData(
        `GeneralDocument/DownloadDocument?documentKey=${key}`
      )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          

          fileLink.href = fileURL;
          fileLink.setAttribute("download", name || 'file' );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          this.$emit("alertError", error);
          // this.alertError(error.response.data.message)
        });
    },
    deleteAttachment(index, key) {
      ApiService.delete(
        `GeneralDocument/delete?documentKey=${key}`
      )
        .then(() => {
          delete this.customerForm.identificationDocuments[index].attachments;
          this.customerForm.identificationDocuments[index].showDoc = false;
        })
        .catch((error) => {
          this.$emit("alertError", error.response.data.message);
        });
    },
    onFileChange(id, index) {
      const currentRef = this.$refs.myFiles.find(item => item.id === id);
      this.myFiles = currentRef.files[0];
      this.customerForm.identificationDocuments[index].filename = this.myFiles.name;
      this.customerForm.identificationDocuments[index].fileSize = this.myFiles.size;
    },
    callFileUpload(id) {
      let input = document.getElementById(id);
      input.click();
    },
    uploadFile(index) {
      if(!this.customerForm.identificationDocuments[index].validUntil) {
          this.$emit("alertError", "Document ID expiry date is required.");
      }
      const formData = new FormData();
      formData.append("uploadFile", this.myFiles);
      this.fileLoading = true;
      ApiService.postFormData(
        `GeneralDocument/UploadAttachmentForCustomer`,
        formData
      )
        .then((resp) => {
          let desc = "File successully uploaded";
          this.customerForm.identificationDocuments[index].showDoc = true;
          this.customerForm.identificationDocuments[index].filename = '';
          this.customerForm.identificationDocuments[index].fileSize = 0;
          this.customerForm.identificationDocuments[index].attachments = [{
            documentDescription: resp.data.data.documentKey,
            documentFileSize:  resp.data.data.fileSize,
            documentName: resp.data.data.fileName,
            documentOriginalFileName: resp.data.data.originalName,
            documentType: resp.data.data.fileType,
            documentLocation: resp.data.data.fileUrl,
          }];
          this.$emit("alertSuccess", desc);
          this.fileLoading = false;
          this.filename = "";
          this.fileSize = "";
        })
        .catch((error) => {
          this.$emit("alertError", error.response.data.message);
          this.fileLoading = false;
        });
    },
    updateEmployer() {
      this.customerForm.employeeKey = this.employer.employerKey;
    },
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.$emit("alertError", error);
      }
    },
    getCustomerAccountOfficer(key) {
      this.accountOfficers = [];
      AccessApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${key}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      })
    },
    async getBranchCenters(key) {
      const model = {
        searchParam: null,
        branchKey: key,
      };
      try {
        const res = await centresService.search(model);
        this.branchCenter = [];
        this.branchCenter = res.data.data.items;
        // this.isLoading = false;
      } catch (e) {
        // this.isLoading = false;
        const error = e.response.data.message;
        this.$emit("alertError", error);
      }
    },
    branchSelectChange(event, edit = false) {
      if (edit) {
        this.getBranchCenters(event);
      } else {
        const key = event.target.value;
        this.getBranchCenters(key);
        this.getCustomerAccountOfficer(key);
      }
    },
    validateCustomerForm() {
      this.clearCustomerValidation();
      if (
        this.customerForm.customerFirstName &&
        this.customerForm.customerLastName &&
        this.customerForm.uniqueCustomerId &&
        this.customerForm.customerBirthDate &&
        this.customerForm.customerGender &&
        this.customerForm.assignedBranchKey
      ) {
        return true;
      }

      if (!this.customerForm.customerFirstName) {
        this.customerErrorForm.customerFirstName = true;
      }
      if (!this.customerForm.customerLastName) {
        this.customerErrorForm.customerLastName = true;
      }
      if (!this.customerForm.uniqueCustomerId) {
        this.customerErrorForm.uniqueCustomerId = true;
      }
      if (!this.customerForm.customerBirthDate) {
        this.customerErrorForm.customerBirthDate = true;
      }
      if (!this.customerForm.customerGender) {
        this.customerErrorForm.customerGender = true;
      }
      if (!this.customerForm.customerEmailAddress) {
        this.customerErrorForm.customerEmailAddress = true;
      }
      if (!this.customerForm.assignedBranchKey) {
        this.customerErrorForm.assignedBranchKey = true;
      }

      return false;
    },
    checkCustomFieldAvail(customField) {
      if (this.customerForm.customerTypesKey !== null) {
        let visible;
        visible = customField.customFieldLinks.some(
          (x) => x.entityLinkedKey == this.customerForm.customerTypesKey
        );
        return visible;
      } else {
        return true;
      }
    },
    clearCustomerValidation() {
      this.customerErrorForm = {
        assignedBranchKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
        employeeKey: false
      };
    },
    checkDocumentExpiryDate() {
      let checks = [];
      this.customerForm.identificationDocuments.forEach(item => {
        if(!item.validUntil) {
          checks.push(true);
        } else {
          checks.push(false);
        }
      });
      const validUntilError = checks.includes(true) ? true : false;
      return validUntilError;
    },
    editAccountValidateNumber(num, fieldSetIndex, fieldIndex) {
      if(isNaN(parseFloat(num))) {
        this.$emit("alertWarning", "Invalid input value, Number required.");
        this.customerForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].value = 0;
      }
    },
    editAccountInput(event, fieldSetIndex, fieldIndex) {
      this.customerForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].value = {};
      this.customerForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].value = event;
    },
    async editAccountQuerySearch(query, fieldSetIndex, fieldIndex) {
      let searchQuery = query;
      let searchArea =
        this.customerForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].dataType.toLowerCase() == "customer link"
          ? "customer"
          : this.customerForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].dataType.toLowerCase() == "group link"
          ? "group"
          : "user";

      try {
        const response = await ApiService.post(`GlobalSearch`,
          { searchQuery, searchCategory: searchArea }
        );
        this.customerForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].searchValue = 
          response.data.data ? response.data.data : [];
        this.searchValues = response.data.data ? response.data.data : [];
      } catch(e) {
        this.customerForm.customFieldSetFieldsAndValue[fieldSetIndex].fields[fieldIndex].searchValue = [];
      }
    },
    validateEmailCustomer(email) {
      let validRegex1 = /\S+@\S+\.\S+/;
      if(!email.trim().match(validRegex1)) {
        this.customerErrorForm.customerEmailAddress = true;
        // return this.alertWarning('Please provide a valid email.');
      }

      this.customerErrorForm.customerEmailAddress = false;
    },
    validateBVN(bvn) {
      if(bvn) {
        if(bvn.length != 11){ 
          this.customerErrorForm.bvn = true;
          this.$emit('alertWarning', "BVN cannot be more/less than 11 characters.");
          return true;
        } else {
          this.customerErrorForm.bvn = false;
          return false;
        }
      }
    },
    limitBVNEntry(event) {
      const { value } = event.target;
      if (value.length > 11) {
        this.customerForm.bvn = this.limitValue;
      } else {
        this.limitValue = value;
      }
    },
    async saveCustomerForm (e) {
      e.preventDefault();
      if (this.validateCustomerForm()) {
        if (this.requiredBVN && !this.customerForm.bvn) {
          this.$emit('alertWarning', "BVN field is required.");
          return;
        }
        if (this.validateBVN(this.customerForm.bvn)) {
          return;
        }
        if (this.requiredEmail && !this.customerForm.customerEmailAddress) {
          this.$emit('alertWarning', "Email field is required.");
          return;
        }
        if (this.customerErrorForm.customerEmailAddress) {
          this.$emit('alertWarning', "Please provide a valid email.");
         return;
        }
        if (this.requiredEmployer && !this.customerForm.employeeKey) {
          this.alertWarning("Employer is required.");
          return;
        }
        if (this.checkDocumentExpiryDate()) {
          this.$emit('alertWarning', "Valid until date is required for all documents added.");
          return;
        }
        this.isSaving = true;

        try {
          this.customerForm.customFieldValues = [];
          // this.customerForm.updateField = {};
          // this.customerForm.updateField.values = [];
          // this.customerForm.updateField.customerKey = "";
          if (this.customerForm.customFieldSetFieldsAndValue.length) {
            this.customerForm.customFieldSetFieldsAndValue.forEach((item) => {
              for (let i = 0; i < item.fields.length; i++) {
                if (['customer link', 'group link', 'user link'].includes(item.fields[i].dataType.toLowerCase())) {
                  if (item.fields[i].value) {
                    const updateValue = item.fields[i].value;
                    item.fields[i].value = updateValue.linkKey;
                  }
                }
                let customObj = {
                  customFieldValue: item.fields[i].value,
                  customFieldKey: item.fields[i].fieldKey,
                  scoreAmount: 0,
                  indexInList: 0,
                  customFieldSetGroupIndex: 0,
                };
                // this.customerForm.updateField.values.push(customObj);
                this.customerForm.customFieldValues.push(customObj);
              }
            });
            // this.customerForm.updateField.customerKey = this.customerForm.customerKey;
            // this.$emit("updateCustomerEditCustomFields", this.customerForm.updateField)
          }
          if (this.customerForm.identificationDocuments.length) {
            const identificationDocuments = this.customerForm.identificationDocuments.map(item => ({
              attachments: item.attachments,
              documentId: item.documentId,
              documentType: item.documentType,
              documentIdTemplate: item.documentIdTemplate,
              identificationDocumentTemplateKey: item.identificationDocumentTemplateKey,
              issuingAuthority: item.issuingAuthority,
              validUntil: item.validUntil
            }));
            this.customerForm.identificationDocuments = [...identificationDocuments];
          }
          const res = await customerService.updateCustomer(
            this.customerForm.customerKey,
            this.customerForm
          );
          //document.getElementById("customerFormCloseButton").click();
          if (res.data.status) {
            document.getElementById("customerFormCloseButton").click();
            this.isSaving = false;
            this.limitValue = "";
            this.$emit("alertSuccess", res.data.message);
            this.$emit("refresh");
            this.$emit("close");
          } else {
            this.isSaving = false;
            this.$emit("alertWarning", res.data);
          }
          // this.isSaving = false;
          // this.$emit("alertSuccess", "Customer updated.");
          // this.$emit("refresh");
          // this.$emit("close");
        } catch (e) {
          this.isSaving = false;
          const error = e?.response?.data?.message;
          if(e.response.status == 409) {
            this.$emit('alertWarning', e.response.data);
          } else {
            this.$emit('alertError', error);
          }
        }
      } else {
        this.$emit("alertWarning", "Some form fields are required.");
      }
    },
    preOpenCustomerForm(customerTypeKey = null) {
      this.clearCustomerForm();
      this.customerForm.customerTypesKey = customerTypeKey;
      this.getCustomFormFields("Customers", customerTypeKey);
    },
    clearCustomerForm: function () {
      this.requiredBVN = false;
      this.requiredEmail = false;
      this.requiredEmployer = false;
      this.customerForm = {
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        customerHomePhone: null,
        uniqueCustomerId: null,
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: "English",
        customFieldValues: [],
        identificationDocuments: [],
        selectedIdTemplate: null,
        employeeKey: null,
        // customerHomeAddress: null
        streetAddressLine1: null,
        streetAddressLine2: null,
        city: null,
        state: null,
        zipPostalCode: null,
        country: null,
      };
    },
    // Form inti
    async getCustomFormFields(type, key) {
      this.customFields = [];
      try {
        this.requiredBVN = this.customerClientTypes.find(item => key === item.customerTypeKey).requireBvn;
        this.requiredEmail = this.customerClientTypes.find(item => key === item.customerTypeKey).requireEmailAddress;
        this.requiredEmployer = this.customerClientTypes.find(item => key === item.customerTypeKey).requireEmployer;
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
          entityLinkedKey: key,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e?.response?.data?.message;
        if (error) return this.$emit("alertError", error);
      }
    },
    transformDocDates(arr) {
      arr.forEach((x) => {
        x.validUntil = moment(x.validUntil).format("YYYY-MM-DD");
      });
      return arr;
    },
    async getAllUsers() {
      this.allUsers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: []
        }
        AccessApiService.post('ProleanUser/GetUsersWithoutPermission', userModel)
        .then((resp) => {
          this.allUsers = resp.data.data;
          this.userLoading = false;
        })
      } catch(e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit('alertError', error);
      }
    },
    getAllEmployers() {
      const paged = {
        pageIndex: 1,
        pageSize: 99999,
      };

      GeneralApiService.post(
        "Employer/Employers",
        paged
      )
        .then((res) => res.data.data.items)
        .then((res) => {
          this.allEmployers = res;
        });
    },
  },
  watch: {
    customerFo(nextProps) {
      const ob = { ...this.$data.customerForm, customFieldSetFieldsAndValue: [] };
      var self = this;
      this.employer = nextProps.employeeKey
        ? this.sortedEmployers.find((item) => item.employerKey === nextProps.employeeKey)
        : "";
      Object.keys(nextProps).map(async function (key) {
        if (ob.hasOwnProperty(key) && nextProps.hasOwnProperty(key)) {
          self.customerForm[key] = nextProps[key];

          if (key === "customerBirthDate") {
            self.customerForm[key] = moment(nextProps[key]).format(
              "YYYY-MM-DD"
            );
            return;
          }

          if (key === "identificationDocuments") {
            self.customerForm[key] = self.transformDocDates(nextProps[key]);
            return;
          }

          if (key === "assignedCentreKey") {
            await self.branchSelectChange(nextProps.assignedBranchKey, true);
            self.customerForm[key] = nextProps[key];
            return;
          }

          if (key === "assignedUserKey") {
            await self.getCustomerAccountOfficer(nextProps.assignedBranchKey);
            self.customerForm[key] = nextProps[key];
            return;
          }
          if (key === "bvn") {
            self.limitValue = nextProps[key];
          }
        }
      });
    },
  },
  computed: {
    ...mapState({
      enums: (state) => state.loan.enums,
      loading: (state) => state.loan.loading_all,
      menu: (state) => (state.views.menu.data ? state.views.menu.data : []),
      customerClientTypes: (state) => state.deposit?.customerTypes?.data?.items,
      sortCustomerType() {
        let sorted = this.customerClientTypes.slice().sort((a, b) => {
          let textA = a.clientTypeName.toUpperCase();
          let textB = b.clientTypeName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }); 

        return sorted;
      }
    }),
    sortedUsers() {
      const userArray = this.allUsers;
      return userArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedEmployers() {
      const employerArray = this.allEmployers;
      return employerArray.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    this.getIdTemplates();
    this.getBranches();
    this.preOpenCustomerForm();
    this.getAllEmployers();
    this.getAllUsers();
  },
  filters: {
    kb(val) {
      return Math.floor(val / 1024);
    },
  }
};
</script>